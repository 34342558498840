/* * {outline: 1px solid;} */
/* Dummy rule for easily triggering css parsing */

/* theme.json defines the basic ant design styling. https://ant.design/theme-editor */

:root {
  --color-primary: #00054A;
  --color-orange: #FFA654;
  color: var(--color-primary);
  --whitefade: rgba(255,255,255,.2);
}

html {
  height: 100%;
  background: #FFA654;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0;
  background: radial-gradient(farthest-corner at 50.00% 0.00%, #FFC794 0%, transparent 100%);
  background-size: cover;
  background-repeat: no-repeat;
}
img {
  max-width: 100%;
  height: auto;
}
table {
  width: 100%;
}
th, td {
  vertical-align: top;
  padding: 0 8px;
}
th:first-child {
  padding-left: 0;
}
td:first-child {
  padding-left: 0;
}
th:last-child {
  padding-right: 0;
}
td:last-child {
  padding-right: 0;
}
b, .bold {
  font-weight: 600
}
canvas {
  width: 100%;
  max-width: 100%;
  height: auto;
}
#root, .ant-layout, .ant-layout-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  background: none;
}

/***
Customize ant components
***/
.ant-typography.ant-typography {
  color: inherit;
}
h1.ant-typography {
  font-size: 72px;
  line-height: 1;
  font-weight: 700;
}
h2.ant-typography {
  font-weight: 700;
}
h3.ant-typography {
  font-weight: 700;
}
h4.ant-typography {
  font-weight: 700;
}
h5.ant-typography {
  font-weight: 700;
}
.ant-typography-lg {
  font-size: 20px;
}
hgroup .ant-typography:first-child {
  margin-bottom: 8px;
}
hgroup .ant-typography:last-child {
  margin-top: 0;
}
label h2.ant-typography,
label h3.ant-typography,
label h4.ant-typography,
label h5.ant-typography {
  /* We use heading styles inside form labels. A bit wrong, but not catastrophic so styling the spacing here. */
  margin-bottom: 0;
}

/* Legacy fieldset styling reset hack. Let's see how it works on todays browsers. */
legend {
  padding: 0;
  width: 100%;
  display: table;
}
fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}


.ant-space {
  display: flex;
}

.ant-form-item .ant-form-item-label {
  text-align: unset;
}
.ant-form legend {
  font: inherit;
  margin: 0;
  color: inherit;
  border: none;
}

.ant-btn {
  color: var(--color-primary);
  /* background: white; */
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 16px;
  border-color: transparent;
  /* TODO: ant buttons set a height: XX style. This is a problem if we use buttons for choices and if choice texts can be any length. So make a custom CheckableChoiceChip or something for default option/choice button viz. */
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: transparent;
}
.ant-btn.ant-btn-lg {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: none;
  padding: 0 16px;
}
.ant-btn.ant-btn-sm {
  font-style: 16px;
  padding: 0 8px;
}
.ant-btn-primary {
  color: white;
  box-shadow: none;
}
.ant-btn-link {
  padding: 0 !important; /*Ugh forced override*/
}
.ant-btn-checkable {
  text-align: left;
  position: relative;
}
.ant-btn-float {
  box-shadow: 0 7px 15px -15px #FFA654, 0 3px 7px rgb(141 68 0);
  box-shadow: 0 4px 15px -13px #FFA654, 0 3px 8px -5px rgb(141 68 0), 0 0px 5px -2px rgba(0,0,0,.33);
  box-shadow: 0 4px 15px -13px #FFA654, 0 3px 8px -5px rgb(141 68 0), 0 0px 5px -2px rgb(114 55 0 / 27%);
  box-shadow: 0 4px 15px -13px #FFA654, 0 3px 8px -5px rgb(141 68 0), 0 0px 5px -2px rgb(114 55 0 / 27%), 0 -5px 5px -5px rgba(255,255,255,.5);
}
button.transparent {
  appearance: none;
  font: inherit;
  text-align: inherit;
  display: block;
  width: 100%;
  padding: 0;
  color: inherit;
  background: transparent;
  border: none;
}

/* Force ant design stuff to inherit color, doesn't need so much theme config when color is inherit all over. */
.ant-form,
.ant-form-item,
.ant-form-item .ant-form-item-label > label {
  color: inherit;
}
.ant-input {
  text-align: inherit !important;
  border-color: transparent;
}
.ant-input:hover {
  border-color: transparent;
}

.ant-select-dropdown .ant-select-item-option-selected {
  color: white !important;
  background: var(--color-primary) !important;
}

.ant-card {
  color: inherit;
  background-color: rgba(255,255,255,.33);
}

.ant-collapse {
  overflow: hidden;
}
.ant-collapse:not(.ant-collapse-ghost) > .ant-collapse-item > .ant-collapse-header {
  background-color: rgba(255,255,255,.33);
}
.ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
  border-bottom-left-radius: 0 !important; /*TODO: sorry for all the !important. Ant design has quite a bit of specificity in their selectors that need to be overridden. */
  border-bottom-right-radius: 0 !important;
}
.ant-collapse-header-text .ant-typography {
  margin: 0;
}


.ant-tag {
  color: inherit;
  border-radius: 99em;
  margin: 0;
}
.ant-tag.ant-tag {
  display: inline-flex;
  align-items: center;
  text-align: center;
  line-height: normal;
  padding: 0 12px;
  height: 32px;
}
.ant-tag-quest {
  font-size: 22px;
  font-weight: 700;
  height: 44px;
  padding: 6px 16px;
}
.ant-tag-quest-icon {
  margin: 0 0 0 8px;
}
.ant-tag-quest svg {
  display: block;
  font-size: 1em;
  width: 1em;
  height: auto;
}

.ant-avatar {
  font-weight: 700;
}

.ant-list-item:first-child {
  padding-top: 0;
}
.ant-list-item:last-child {
  padding-bottom: 0;
}
/*This is insanity*/
.ant-list.ant-list .ant-list-item,
.ant-list.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-content,
.ant-list.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title,
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: var(--color-primary) !important;
}



/***
Our own components
***/
.view-container {
  flex: 1 0 auto;
  position: relative;
  margin: 24px auto 0 auto;
  width: 100%;
  max-width: 800px;
  background: rgba(255,255,255,.2);
  padding: 8px 32px 32px 32px;
  border: 1px solid rgba(255,255,255,.5);
  border-radius: 16px 16px 0 0;
  overflow: hidden;
}
@media (max-width: 840px) {
  .view-container {
    margin-top: 0;
    border-radius: 0;
    max-width: 100%;
    padding: 0 16px 16px 16px;
  }
}
.view-title {
  margin-top: 60px;
}
.view-subtitle {
  font-size: 44px;
}
.navbar {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 32px;
  /* background: rgba(0,0,0,.1); */
}
@media (max-width: 840px) {
  .navbar {
    padding: 16px 16px;
  }
}
.navbar-groups {
  margin-top: -16px;
}
.actionbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  gap: 8px;
}

.vuo-question {

}
.vuo-addmore {
  display: grid;
  grid-template:
    "input action"
    "error error"
    "choices choices"
    / 1fr min-content
  ;
  align-items: end;
  gap: 8px;
}
.vuo-options {
  display: grid;
  justify-items: stretch;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;
}
/* TODO: container query instead of media query! */
@media (max-width: 640px) {
  .vuo-options {
    grid-template-columns: 1fr;
  }
}
.vuo-option {
  cursor: pointer;
}
.vuo-input {
  grid-area: input;
}
.vuo-error {
  grid-area: error;
}
.vuo-error {
  grid-area: error;
}
.vuo-action {
  grid-area: action;
}
.vuo-choices {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  grid-area: choices;
}
.vuo-actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0 0 0;
}


.recipe-thumb {
  text-align: center;
  overflow: hidden;
  position: relative;
  padding: 8px;
  border-radius: 8px;
  background: var(--whitefade);
  transition: all .33s ease;
}
button:hover .recipe-thumb {
  box-shadow: 0 5px 30px -10px #d2680699;
  cursor: pointer;
}
button:active .recipe-thumb {
  box-shadow: 0 0 20px -5px #d26806bb;
}
[aria-pressed="true"] .recipe-thumb {
  position: relative;
  z-index: 1;
  transform: scale(1.08);
  background: white;
}
.recipe-thumb-emoji {
  font-size: 64px;
}
.recipe-thumb .ant-typography {
  margin: 0;
}
.recipe-thumb-title.recipe-thumb-title { /* Ant design uses tag qualified selectors, need to increase specificity, sorry. */
  margin: 0 0 8px 0;
}
.recipe-thumb-avatars {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: end;
  gap: 8px;
}
.recipe-thumb-tags {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 16px 0 8px 0;
}
.recipe-thumb-overlay {
  position: absolute;
  inset: -8px;
  display: grid;
  place-items: start end;
  display: none;
}
.recipe-thumb-badge {
  font-size: 32px;
  opacity: .5;
  position: absolute;
  top: 8px;
  left: 8px;
}
.recipe-thumb-description {
  margin: 0 0 8px 0;
}






.recipe-layout {
  display: grid;
  gap: 12px;
  grid-template:
    "thumb thumb"
    "requirements nutrition"
    "steps steps"
    / 1fr 200px
  ;
}
@media (max-width: 640px) {
  .recipe-layout {
    grid-template:
      "thumb"
      "requirements"
      "nutrition"
      "steps"
    ;
  }
}
.recipe-layout .recipe-thumb {
  grid-area: thumb;
}
.recipe-requirements-card {
  grid-area: requirements;
}
.recipe-nutrition-label-card {
  grid-area: nutrition;
}
.recipe-nutrition-label-card .ant-card-body {
  padding: 12px;
}
.recipe-steps-card {
  grid-area: steps;
}
.recipe-summary-emoji {
  display: block;
  margin-top: -64px;
  font-size: 64px;
}
.recipe-nutrition-label-card table td {
  text-transform: capitalize;
}

.recipe-direction-card {}
.recipe-direction-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.recipe-direction-card .recipe-direction-emoji {
  display: block;
  text-align: center;
  margin-top: -32px;
  font-size: 64px;
}

.avatar-large {
  display: inline-flex;
  align-items: center;
  padding: 2px 12px 2px 2px;
  background: rgba(255,255,255,.5);
  border-radius: 99em;
}
.avatar-large. .ant-avatar {
  margin-right: 8px;
}

.grocery-list-card .ant-card-body {
  padding: 8px 0;
}
.grocery-list-card .ant-list-item {
  padding-right: 0;
}

.squircle {
  display: grid;
  grid-template: "a";
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  justify-items: stretch;

  /*TODO: fancy temp styles*/
  color: white;
}
.squircle > * {
  grid-area: a;
}
.squircle-bg {
  display: grid;
  align-items: stretch;
  justify-content: stretch;
  grid-template:
    "top-left top top-right" var(--radius)
    "left center right" 1fr
    "bottom-left bottom bottom-right" var(--radius)
    /  var(--radius) 1fr  var(--radius)
  ;
}
.squircle-bg svg {
  width: var(--radius);
  height: var(--radius);
}
.squircle-bg div {
  background: var(--background);
  width: 100%;
  height: 100%;
}
.squircle-bg :nth-child(3) {transform: scaleX(-1);}
.squircle-bg :nth-child(7) {transform: scaleY(-1);}
.squircle-bg :nth-child(9) {transform: scaleX(-1) scaleY(-1);}
.squircle-content {
  position: relative;
  padding: calc(var(--radius) / 2);
}

/* Utilities */
.screen-reader-only {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}
.text-bold {
  font-weight: 600;
}
.text-large {
  font-size: 1.66em;
  line-height: 1.3;
  font-weight: 500;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

/* Flex utils from bootstrap.css */
.flex {display: flex;}
.flex-column {display: flex;  flex-direction: column;}
.justify-content-start {justify-content: start;}
.justify-content-center {justify-content: center;}
.align-items-start {align-items: start;}
.align-items-center {align-items: center;}
.align-items-stretch {align-items: stretch;}
.flex-grow-1 {flex-grow: 1;}
.flex-shrink-1 {flex-grow: 1;}
.flex-wrap {flex-wrap: wrap;}
.flex-break {flex: 0 0 100%}
.gap-8 {gap: 8px;}
.fade {
  opacity: .75;
}
.nowrap {
  white-space: nowrap;
}

.width-full {width: 100%;}
.center-x {margin-left: auto !important; margin-right: auto !important;}
.mg-0 {margin: 0 !important;}
.mgt-0 {margin-top: 0 !important;}
.mgb-0 {margin-bottom: 0 !important;}

.vertical-align-top,
.vertical-align-top td {
  vertical-align: top;
}


/*
  Dev Validations & Warnings
  These will yell at you for UX errors that are commonly made. (that we can catch with CSS)
*/

/*Bonkers specificity just in case*/
[disabled][disabled][disabled][disabled][disabled][disabled][disabled][disabled][disabled] {
  content: "DO NOT USE disabled ATTRIBUTE. Yes, this is important" !important;
  border-width: 5px !important;
  border-style: dashed !important;
  border-color: red !important;
  box-shadow: 0 0 0 9px white, 0 0 0 6px red, 0 0 0 3px white !important;
}
[disabled][disabled][disabled][disabled][disabled][disabled][disabled][disabled][disabled]::before {
  content: "Do not use disabled attribute. Yes, this is important" !important;
}
:placeholder-shown {
  content: "Do not use placeholder attribute. Yes, this is important" !important;
  border-width: 5px !important;
  border-style: dashed !important;
  border-color: red !important;
  box-shadow: 0 0 0 3px white, 0 0 0 6px red, 0 0 0 9px white !important;
}